<script>
// import Layout from '@/views/layouts/main2'
import simplebar from 'simplebar-vue'
import i18n from '@/i18n'
import global_func from '@/global_function'
import moment from 'moment'
// import moment from 'moment'

import 'ol/ol.css'
import * as ol from 'ol'
import * as layer from 'ol/layer'
import * as source from 'ol/source'
import * as proj from 'ol/proj'
import * as geom from 'ol/geom'
import * as style from 'ol/style'
import LayerSwitcher from 'ol-layerswitcher'
// import Overlay from 'ol/Overlay'
// import CircleStyle from 'ol/style/Circle'
import rhm from '@/assets/js/routing_here_map.js'

/**
 * Starter page component
 */
export default {
  page: {
    // title: 'หน้าว่าง',
    meta: [{ name: 'description' }],
  },
  components: { simplebar }, //
  props: {
    obj: {
      type: Object,
      default: () => {
        return { message: 'no obj' }
      },
    },
  },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('plans_display')
    const src = require('@/assets/images/ol/location_no_1.png')
    const src2 = require('@/assets/images/ol/location_no_2.png')
    return {
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      lang: lang_obj,
      plan_data: this.obj,
      id_order_tracking: '', //
      created: '',
      order_period: '',
      pickup_time_1: '',
      pickup_time_2: '',
      display_pickup: '',
      period_display: '',
      goods_type_name_full: '',
      quantity: '',
      question:'',
      arrival_address: '',
      departure_address: '',
      total_distance: '',
      //truck_type_group: "",
      vehicle_type_group_id: '',
      truck_type_name: '',
      other_service_display: '',
      expected_price: '',
      arrival_lat_lon: '',
      departure_lat_lon: '',
      base_layer : null,

      plan_data_selcet: {
        conv_price: '235.00',
        goods_type_name: 'สินค้าอิเล็คทรอนิคสินค้าอิเล็คทรอนิค2',
        goods_type_name_full: 'สินค้าอิเล็คทรอนิคสินค้าอิเล็คทรอนิค2',
        pickup_time: '30/07/2021 (ตลอดทั้งวัน)',
        status_name: 'รอการตอบรับ',
        truck_type_group: 1,
        truck_type_name: '4 ล้อ - คอก, ...',
      },
      heightMap: '800px', // กำหนดความสูงด้วย methods getHeightDivMap()
      map: null,
      center: null,
      pick_up_1: null, // dtc สำนักงานใหญ่
      drop_1: null, // mega bangna
      zoom: 15,
      rotation: 0,

      src: src,
      src2: src2,
      coordinates: [11207429.40090933, 13541935.007697027],
      features: [
        {
          type: 'Feature',
          id: 'one',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [11207429.40090933, 13541935.007697027],
          },
        },
      ],
      pinPointLayer: [],
      directionLayer: [], // layer ของเส้นทางจากจุดรับส่ง
      directions_data: [], // ข้อมูลเส้นทางที่ได้จาก api
      data_location: [
        {
          id: 1,
          icon: 'ri-map-pin-fill',
          texts: '',
          texts_en: '',
          LAT: '',
          LON: '',
          location_name: '',
          location_addr: '',
          cood: null,
          iconLocation: null,
        },
        {
          id: 2,
          icon: 'ri-map-pin-fill',
          texts: '',
          texts_en: '',
          LAT: '',
          LON: '',
          location_name: '',
          location_addr: '',
          cood: null,
          iconLocation: null,
        },
      ],
      activeLocation_index: '',
      
    }
  },
  watch: {},
  beforeMount() {},
  mounted() {},
  methods: {
    bt_detail(data) {
      // console.log(data)
      this.id_order_tracking = data.id_order_tracking
      this.created = data.created
      this.order_period = data.order_period

      //SET DEFAULT
      this.pickup_time_1 = '2021-08-27 00:00:00'
      this.pickup_time_2 = '2021-08-27 23:59:59'
      if(typeof data.pickup_time_1 != 'undefined'){
        this.pickup_time_1 = data.pickup_time_1
        this.pickup_time_2 = data.pickup_time_2
      } else if(typeof data.rawData.pickup_time_1 != 'undefined'){
        this.pickup_time_1 = data.rawData.pickup_time_1
        this.pickup_time_2 = data.rawData.pickup_time_2
      }
      this.display_pickup = this.getPickupTime(this.pickup_time_1, this.pickup_time_2)

      this.period_display = data.order_period_display
      this.goods_type_name_full = data.good_type_name
      this.quantity = data.quantity
      this.question = data.question
      this.arrival_address = data.arrival_address
      this.departure_address = data.departure_address
      this.total_distance = data.total_distance
      this.vehicle_type_group_id = data.vehicle_type_group_id //this.truck_type_group = data.truck_type_group
      this.truck_type_name = data.truck_type_name
      this.other_service_display = data.other_service_display
      this.expected_price = data.expected_price
      this.arrival_lat_lon = data.arrival_lat_lon
      this.departure_lat_lon = data.departure_lat_lon

      this.$bvModal.show('modal_detail')
      setTimeout(() => {
        this.arrBaseLayer()
        this.initiateMap()
      }, 500)
      
    },
    arrBaseLayer(){
      // this.base_layer = [{"rr":"1"}]
      var arr = []
      var P_map = {}
      var dee_map_th2 = new layer.Tile({
        title: 'Dee-MapV2 (Thai)',
        type: 'base',
        visible: false,
        maxZoom: 22,
        source: new source.TileWMS({
          url: 'https://www.deemap.com/DeeMap/gwc/service/wms?', 
          params: {
            'customer_key': "9P8qe4ndbB8ep6hJ",
            'LAYERS': 'DeeMap2_THA_Map',
            'FORMAT': 'image/png8',
            'VERSION': '1.1.1',
            'TILED': true,
          },
        }),
      })
      arr.unshift(dee_map_th2)
      // console.log(arr)
      P_map.dee_map_th2 = dee_map_th2

      var dee_map_en2 = new layer.Tile({
        title: 'Dee-MapV2 (En)',
        type: 'base',
        visible: false,
        maxZoom: 22,
        source: new source.TileWMS({
          url: 'https://www.deemap.com/DeeMap/gwc/service/wms?',
          params: {
            customer_key: "9P8qe4ndbB8ep6hJ",
            'LAYERS': 'DeeMap2_THA_Map_EN',
            'FORMAT': 'image/png8',
            'VERSION': '1.1.1',
            'TILED': true,
          },
        }),
      })
      arr.unshift(dee_map_en2)
      P_map.dee_map_en2 = dee_map_en2

      var layerNokiaHere = new layer.Tile({
        title: 'HereMap',
        type: 'base',
        visible: false,
        maxZoom: 18,
        source: new source.XYZ({
          url: 'http://1.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?app_id=o9W16gheao25dXp9a9Kg&app_code=Smo6mT91hk9YhqJSclXrSA&lg=THA&lg2=ENG&xnlp=CL_JSMv2.5.3,SID_A78335BD-4DDE-4BBB-B544-B856888E9E89',
        }),
      })
      arr.unshift(layerNokiaHere)
      P_map.layerNokiaHere = layerNokiaHere

      var satellite = new layer.Tile({
        title: 'Satellite',
        type: 'base',
        visible: false,
        maxZoom: 20,
        source: new source.XYZ({
          url: 'http://1.aerial.maps.cit.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?app_id=o9W16gheao25dXp9a9Kg&app_code=Smo6mT91hk9YhqJSclXrSA&lg=THA&lg2=ENG',
        }),
      })
      arr.unshift(satellite)
      P_map.satellite = satellite

      var openstreetmap = new layer.Tile({
        // A layer must have a title to appear in the layerswitcher
        title: 'OSM',
        // Again set this layer as a base layer
        type: 'base',
        visible: true,
        source: new source.OSM(),
      })
      arr.unshift(openstreetmap)
      P_map.openstreetmap = openstreetmap

      this.base_layer = {arr: arr, POINT: P_map}
    },
    initiateMap() {
      var map = new ol.Map({
        target: this.$refs.map_1,
        // layers: [
        //   new layer.Tile({
        //     source: new source.OSM(),
        //   }),
        // ],
        view: new ol.View({
          center: proj.fromLonLat([100.60379663561206, 13.676865941765966]),
          zoom: 10,
        }),
      })
      this.map = map
      var arr_layer = this.base_layer.arr
      var group_base = new layer.Group({
        // A layer must have a title to appear in the layerswitcher
        'title': 'Base Layer',
        fold: 'open',
        layers: arr_layer,
      })
      this.map.addLayer(group_base)

      var layerSwitcher = new LayerSwitcher({
        reverse: true,
        groupSelectStyle: 'group',
      })
      map.addControl(layerSwitcher)

      let departure_lat_lon = this.departure_lat_lon
      let arrival_lat_lon = this.arrival_lat_lon

      var departure = departure_lat_lon.split('|')
      var ariival = arrival_lat_lon.split('|')

      this.data_location[0].LAT = parseFloat(departure[0])
      this.data_location[0].LON = parseFloat(departure[1])
      this.data_location[1].LAT = parseFloat(ariival[0])
      this.data_location[1].LON = parseFloat(ariival[1])

      // console.log(departure)
      // console.log(ariival)

      let coordinate = [parseFloat(departure[1]), parseFloat(departure[0])]
      let coordinate2 = [parseFloat(ariival[1]), parseFloat(ariival[0])]
      this.setPinPointLocation(coordinate, 0)
      setTimeout(() => {
        this.setPinPointLocation(coordinate2, 1)
      }, 500)
    },
    setPinPointLocation(coordinate, index) {
      this.activeLocation_index = index
      // remove layer if have layer already
      if (this.pinPointLayer[this.activeLocation_index]) {
        this.map.removeLayer(this.pinPointLayer[this.activeLocation_index])
      }

      //set a new pinpoint at clicked coordinates
      var new_point = new ol.Feature({
        geometry: new geom.Point(proj.fromLonLat([coordinate[0], coordinate[1]])),
      })

      // check location index for select icon pinpoint
      var pinpoint = this.src
      if (this.activeLocation_index == 0) {
        pinpoint = this.src
      } else {
        pinpoint = this.src2
      }

      //set style of point
      new_point.setStyle(
        new style.Style({
          image: new style.Icon({
            // color: 'red',
            crossOrigin: 'anonymous',
            scale: 1,
            anchor: [0.5, 1],
            anchorOrigin: 'top-left',
            src: pinpoint,
          }),
        }),
      )

      //put point on layer
      this.pinPointLayer[this.activeLocation_index] = new layer.Vector({
        source: new source.Vector({
          features: [new_point],
        }),
      })

      // set name of layer
      this.pinPointLayer[this.activeLocation_index].set('name', 'point_' + this.activeLocation_index)
      // add point on map , set new center point and zoom value
      let new_center = proj.fromLonLat([coordinate[0], coordinate[1]])

      // view.animate({
      //   center: new_center,
      //   duration: 2000,
      // })

      this.map.addLayer(this.pinPointLayer[this.activeLocation_index])

      var zoom = this.map.getView().getZoom()
      if (zoom < 12) {
        zoom = 12
      }
      this.map.getView().animate({
        center: new_center,
        zoom: zoom,
        duration: 800,
      })
      // set route when have 2 point already
      var lo_1 = this.data_location[0]
      var lo_2 = this.data_location[1]
      let check1 = lo_1.LAT != null && lo_1.LAT != undefined && lo_1.LAT != ''
      let check2 = lo_2.LAT != null && lo_2.LAT != undefined && lo_2.LAT != ''
      if (check1 && check2 && index == 1) {
        // local routing
        // this.calDirections()
        // here map routing
        this.getDirectionsService_HereMap(lo_1, lo_2)
      }
    },
    getDirectionsService_HereMap_old(lo_1, lo_2) {
      var that = this
      var app_id = 'o9W16gheao25dXp9a9Kg' //'V78Ttyq1RSEy7KsExlfq'//'o9W16gheao25dXp9a9Kg'
      var app_code = 'Smo6mT91hk9YhqJSclXrSA' //'nqGgcND3Yoof9VDmsYc7Hg'//'Smo6mT91hk9YhqJSclXrSA'

      //    var app_id = 'rBwB1Fllq3IHyosgCFs5'
      //    var app_code = '-a-M-daFoGNJuMa7DjF4RA'

      // eslint-disable-next-line no-undef
      var platform = new H.service.Platform({
        app_id: app_id, //Key fluke
        app_code: app_code, //Key fluke
        'apikey': 'Due7AzLBaEnUBQSBPunaYDOLfk4fnPCQe1YuN6ys',
      })

      var origin = lo_1.LAT + ',' + lo_1.LON
      var destination = lo_2.LAT + ',' + lo_2.LON

      // eslint-disable-next-line no-unused-vars
      var routingParameters = {
        mode: 'fastest;truck',
        waypoint0: 'geo!' + origin, //'geo!50.1120423728813,8.68340740740811',
        waypoint1: 'geo!' + destination, //'geo!52.5309916298853,13.3846220493377',
        representation: 'display',
        routeattributes: 'labels',
        alternatives: 2,
      }
      var router = platform.getRoutingService()
      router.calculateRoute(
        routingParameters,
        function (result) {
          if (result.response.route) {
            // console.log('here map')
            // console.log(result.response)
            that.directions_data = result.response
            that.calDirectionsHereMap()
          }
        },
        function (error) {
          console.log(error.message)
        },
      )
    },
    getDirectionsService_HereMap(lo_1, lo_2) {
      let that = this
      rhm.getDirectionsService(lo_1, lo_2, e => {
        // console.log(e)
        that.directions_data = e
        that.calDirectionsHereMap()
      })
    },
    calDirectionsHereMap() {
      // remove layer if have layer already
      if (this.findLayer('route')) {
        this.popupTravelOnMapActiveStatus = false
        this.removeLayerByName('route')
      }

      var locations = []
      var latlonData = this.directions_data.route[0].shape
      // var timeAndDistance = this.directions_data.route[0].leg[0]
      // console.log(latlonData)
      for (let index = 0; index < latlonData.length; index++) {
        let lat_lon = latlonData[index].split(',')
        locations[index] = [parseFloat(lat_lon[1]), parseFloat(lat_lon[0])]
      }
      // console.log(locations)
      // for set new center
      var half = (latlonData.length / 2).toFixed(0)
      var halfRouteData = locations[half]

      var latlan_c = proj.fromLonLat([parseFloat(halfRouteData[0]), parseFloat(halfRouteData[1])])
      var zoom_c = 12
      var distance = parseInt(this.total_distance)
      if (distance < 40) {
        zoom_c = 13
      } else if (distance < 70) {
        zoom_c = 12
      } else if (distance < 100) {
        zoom_c = 11
      } else if (distance < 150) {
        zoom_c = 10
      } else if (distance < 300) {
        zoom_c = 9
      } else if (distance < 500) {
        zoom_c = 8
      } else if (distance < 750) {
        zoom_c = 7
      } else {
        zoom_c = 6
      }
      // console.log(distance)

      this.map.getView().animate({
        center: latlan_c,
        zoom: zoom_c,
        duration: 800,
      })

      var route = new geom.LineString(locations).transform('EPSG:4326', 'EPSG:3857')

      var new_point = new ol.Feature({
        geometry: route,
      })

      //set style of route line
      new_point.setStyle(
        new style.Style({
          stroke: new style.Stroke({
            width: 6,
            color: [0, 152, 209, 0.8],
          }),
        }),
      )
      // set vector
      var vectorLayer = new layer.Vector({
        source: new source.Vector({
          features: [new_point],
        }),
      })
      // set name of vector for easy finding after
      vectorLayer.set('name', 'route')
      this.directionLayer = []
      this.directionLayer = vectorLayer
      // this.map.addLayer(this.directionLayer)
      var that = this
      setTimeout(function() {
        that.map.addLayer(that.directionLayer)
        that.moveLayerBefore(that.findLayer('point_0'), that.findLayer('route'), that.map.getLayers())
        that.moveLayerBefore(that.findLayer('point_1'), that.findLayer('route'), that.map.getLayers())
      }, 1000)
    },
    moveLayerBefore(old_idx, new_idx, layer) {
      if (old_idx === -1 || new_idx === -1) {
        return false
      }
      var that = this
      // layer.removeAt(old_idx)
      layer = that.map.getLayers().removeAt(old_idx)
      // layer = this.map
      this.map.getLayers().insertAt(new_idx, layer)
    },
    findLayer(layer_name) {
      var layer_idx = -1
      var layerArr = this.map.getLayers().getArray()
      for (let index = 0; index < layerArr.length; index++) {
        const element = layerArr[index]
        var this_layer_name = element.get('name')
        if (this_layer_name == layer_name) {
          layer_idx = index
        }
      }
      return layer_idx
    },
    async removeLayerByName(name) {
      var layer = this.map.getLayers().getArray()
      await this.map.removeLayer(layer[this.findLayer(name)])
    },
    getPickupTime(time1, time2) {
      var dateData = global_func.Between2Date(time1, time2)
      var diffTime = (dateData.diffTime / 1000 / 60 / 60)
      var date_return = ''
      if(diffTime > 23.9997222222 && diffTime < 24) {
        date_return = moment(time1).format('dd DD/MM/YYYY') + ' ' + '(ตลอดทั้งวัน)'
      } else {
        date_return = moment(time1).format('dd DD/MM/YYYY HH:mm') + ' - ' + moment(time2).format('dd DD/MM/YYYY HH:mm')
      }
      return date_return
    },
  },
}
</script>

<template>
  <div>
    <!-- <button class="btn btn-lg " style="color: #343C48; background: #FFFFFF; border: 1px solid #B0BAC4;" @click="bt_detail">
    ดูแบบระเอียด
    </button>      -->                         
    <!-- ข้อมูลบริษัท : <b-button size="sm" variant="warning" @click="bt_detail">รายละเอียด</b-button> -->
    <b-modal id="modal_detail" size="xl" title="รายละเอียดแผนงานขนส่ง" title-class="font-18" hide-footer>
      <b-row>
        <b-col lg="6">
          <div class="font-size-18">
            <simplebar class="card-body" style="max-height: 80vh; min-height: 80vh; padding-top: 0px;">
              <!-- padding-top: 0px; -->
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  <span class="plans_detail_topic">
                    {{ lang.plans_detail_display.code }}
                  </span>
                </b-col>
                <b-col lg="9">
                  <span class="font-color-blue">
                    {{ id_order_tracking }}
                  </span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  <span class="plans_detail_topic">
                    {{ lang.plans_detail_display.date_create }}
                  </span>
                </b-col>
                <b-col lg="9">
                  <span class="font-color-blue">
                    {{ created | moment('dd DD/MM/YYYY HH:mm') }} <!-- obj.created | moment('dd DD/MM/YYYY HH:mm') -->
                  </span>
                </b-col>
              </b-row>
                
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  <span class="plans-row-data-content">
                    {{ lang.plans_detail_display.goods_type }}
                  </span>
                </b-col>
                <b-col lg="9">
                  <span class="plans-row-data-content font-color-blue">
                    {{ goods_type_name_full }}
                  </span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  <span class="plans-row-data-content">
                    {{ lang.plans_detail_display.weight }}
                  </span>
                </b-col>
                <b-col lg="9">
                  <span class="plans-row-data-content font-color-blue"> {{ quantity }} {{ lang.tons }} </span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  <span class="plans-row-data-content">
                    {{ lang.plans_detail_display.detail }}
                  </span>
                </b-col>
                <b-col lg="9">
                  <span v-if="obj.question != ''" class="plans-row-data-content font-color-blue">
                    {{ question }}
                  </span>
                  <span v-else class="plans-row-data-content font-color-blue"> - </span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  <span class="plans-row-data-content">
                    {{ lang.plans_detail_display.location }}
                  </span>
                </b-col>
                <b-col lg="9">
                  <ul class="list-unstyled activity-wid">
                    <li class="activity-list">
                      <b-row>
                        <div class="activity-icon avatar-xs">
                          <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                            <i :class="`ri-time-fill`" />
                          </span>
                        </div>
                        <b-col>
                          <span>
                            {{ lang.plans_detail_display.pick_up_1 }}
                          </span>
                          <br />
                          <span class="font-color-blue">
                            {{ departure_address }}
                          </span>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <span class="font-color-blue">
                            {{ display_pickup }} <!-- | moment("DD/MM/YYYY HH:mm") plan_data_selcet.pickup_time -->
                          </span>
                        </b-col>
                      </b-row>
                      <br />
                    </li>
                    <li class="activity-list">
                      <b-row>
                        <div class="activity-icon avatar-xs">
                          <span class="avatar-title bg-soft-primary rounded-circle" style="color: #b0bac4">
                            <i :class="`ri-time-fill`" />
                          </span>
                        </div>
                        <b-col>
                          <span>
                            {{ lang.plans_detail_display.drop_1 }}
                          </span>
                          <br />
                          <span class="font-color-blue">
                            {{ arrival_address }}
                          </span>
                        </b-col>
                      </b-row>
                    </li>
                  </ul>
                </b-col>
              </b-row>
                
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  <span class="plans-row-data-content">
                    {{ lang.plans_detail_display.total_distance }}
                  </span>
                </b-col>
                <b-col lg="9">
                  <span class="plans-row-data-content font-color-blue"> {{ total_distance }} {{ lang.plans_detail_display.km }} </span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  <span class="plans-row-data-content">
                    {{ lang.plans_detail_display.truck_type }}
                  </span>
                </b-col>
                <b-col lg="9">
                  <span class="plans-row-data-title">
                    <img v-if="vehicle_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" width="60px" style="margin-right: 20px" />
                    <img v-else-if="vehicle_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" width="60px" style="margin-right: 20px" />
                    <img v-else-if="vehicle_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" width="60px" style="margin-right: 20px" />
                    <img v-else-if="vehicle_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" width="60px" style="margin-right: 20px" />
                    <img v-else-if="vehicle_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" width="60px" style="margin-right: 20px" />
                    <img v-else-if="vehicle_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" width="60px" style="margin-right: 20px" />
                    {{ truck_type_name }}
                  </span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  <span class="plans-row-data-content">
                    {{ lang.plans_detail_display.services }}
                  </span>
                </b-col>
                <b-col lg="9">
                  <span v-if="obj.other_services != ''" class="plans-row-data-content font-color-blue">
                    {{ other_service_display }} <!-- obj.other_services -->
                  </span>
                  <span v-else class="plans-row-data-content font-color-blue"> - </span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  <span class="plans-row-data-content">
                    {{ lang.plans_detail_display.amount }}
                  </span>
                </b-col>
                <b-col lg="9">
                  <span v-if="plan_data_selcet.conv_price != ''" class="plans-row-data-title"> {{ expected_price }} {{ lang.bath }} </span>
                  <span v-else class="plans-row-data-title"> - </span>
                </b-col>
              </b-row>
            </simplebar>
          </div>
        </b-col>
        <b-col lg="6" class="z-index map">
          <!-- ref="map" -->
          <div id="map_1" ref="map_1" />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<style scoped>
@import '../../../node_modules/ol-layerswitcher/dist/ol-layerswitcher.css';
#map_1 {
  height: 100%;
}
.z-index.map {
  transition: 1s;
  z-index: 1;
  background-color: rgb(255, 255, 255);
}
.z-index.map.active {
  transition: 1s;
  z-index: 8;
  background-color: darkgrey;
}
.modal-scrollbar {
  overflow: hidden scroll; 
}

.modal-scrollbar {
  overflow: auto;
}
.avatar-title {
  /* background-color: #fff !important; */
  background-color: hsla(197, 100%, 89%, 0) !important;
}

</style>
