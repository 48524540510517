<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import Layout from '@/views/layouts/main2'
import simplebar from 'simplebar-vue'
import service from '@/server/http_service'
import i18n from '@/i18n'
import DatePicker from 'vue2-datepicker'
import global_func from '@/global_function'
import moment from 'moment'
import Swal from 'sweetalert2'
import PlanDetail from '@/components/modal/plans_detail'
import ModalMap from '@/components/modal/map'
import DisplayLocation from '@/components/location/display_start_end_location'
import ModalCompany from '@/components/modal/company'
/**
 * Starter page component
 */
export default {
  page: {
    title: 'ประวัติแผนงานขนส่ง',
    meta: [{ name: 'description' }],
  },
  components: { Layout, simplebar, DatePicker, PlanDetail},
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('plans_display')

    return {
      activeScroll:false,
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      check_lang: true,
      lang: lang_obj,

      plans_display_status: false,
      display_as_accept_estimate: false,

      search_date_start: '',
      search_date_end: '',
      search_plans_text: '',
      sort_by_status: '0',
      search_flag : false,

      dataGoodsType_data: [],

      plans_data: [],
      plans_data_status: true,
      plans_row_data: [], // ข้อมูลที่แสดงในแถวแผนงาน
      plan_data_selcet: [],

      dataTruckType: [],
      dataTruckTypeGroup: [],

      //OFFER MODAL
      offer_truck_type: null, //{ id: "3"}, name:"รถบรรทุก 4 ล้อ"
      offer_upload_file: null, //REAL FILE
      offer_upload_display: null, //DISPLAY NAME
      offer_correct_file: true,
      modal_offer: {
        id_order: null,
        good_member_id: null,
        transpot_member_id: null,
        truck_quantity: '',
        cost: '',
        note: '',
      },
      option_truck_type: [],
      //END OFFER MODAL

      //MODAL DETAIL
      offer_detail_data: {},
      //END MODAL DETAIL

      //MODAL CANCEL
      option_cancel: [
        { option_id: 1, name: 'ยกเลิก' },
        { option_id: 2, name: 'รถไม่เพียงพอ' },
      ],
      id_order_cancel: null,
      id_cancel_display: null,
      id_cancel_sel: null,
      //MODAL CANCEL
    }
  },
  watch: {
    search_date_start: {
      handler: function() {
        // // console.log(this.search_date_start)
        this.search_plan('search_date_start')
      },
      deep: true,
    },
    search_date_end: {
      handler: function() {
        // // console.log(this.search_date_start)
        this.search_plan('search_date_end')
      },
      deep: true,
    },
  },
  async mounted() {
    window.addEventListener('wheel', this.bank_check_scroll)
    await this.getTruckType()
    await this.getTruckTypeGroup()
    await this.getDataGoodsType()
    this.search_flag = true
    await this.getPlansData()
  },
  methods: {
    bank_check_scroll(){
      if(this.$refs.bankscrol != undefined) {
        if(this.$refs.bankscrol.scrollElement.scrollTop > 0){
          this.activeScroll = true
        }else{
          this.activeScroll = false
        }
      }
    },
    toTop() {
      this.$refs.bankscrol.scrollElement.scrollTo({ top: 0, behavior: 'smooth' })
    },
    getTruckType() {
      // get ประเภทรถบรรทุก
      return new Promise(resolve => {
        service.postService('getTruckType').then(rp => {
          // console.log(rp)
          this.dataTruckType = rp.data
          resolve(true)
          // // console.log(this.dataTruckType)
        })
      })
    },
    getTruckTypeGroup() {
      return new Promise(resolve => {
        service.postService('getTruckTypeGroup').then(rp => {
          // console.log(rp)
          this.dataTruckTypeGroup = rp.data
          resolve(true)
          // // console.log(this.dataTruckType)
        })
      })
    },
    findTruckTypeName(truck_type_id) {
      var truck_type_name = ''
      for (let index = 0; index < this.dataTruckType.length; index++) {
        const element = this.dataTruckType[index]
        if (truck_type_id == element.id_model) {
          if (truck_type_name != '') {
            truck_type_name += ', '
          }
          if (this.account_lang == 'en') {
            truck_type_name += element.name_model_en
          } else {
            truck_type_name += element.name_model_th
          }
        }
      }
      return truck_type_name
    },
    getPlansData(data_serach, func, id_order) {
      // get ข้อมูล แผนงานขนส่ง
      var that = this
      var mode = func
      var order = id_order
      if (mode == '' || mode == undefined) {
        mode = ''
      }
      if (order == '' || order == undefined) {
        order = ''
      }
      
      this.search_flag = true

      service
        .postService('getPlansDataHis', {
          mode: mode,
          data_serach: data_serach,
          data_serach_start: this.search_date_start,
          date_serach_end: this.search_date_end,
          period: moment().format('YYYY-MM-DD HH:mm'),
          sort_by_order: '2', // วันที่สร้าง
          sort_by_status: this.sort_by_status,
          id_order: order,
          user_data: this.account_data,
        })
        .then(rp => {
          this.plans_data = rp.data
          // console.log(rp.data)
          setTimeout(() => {
            that.createRowOfPlans()
          }, 300)
        })
    },
    createRowOfPlans() {
      var ck1 = this.dataTruckType.length > 0
      var ck2 = this.plans_data.length > 0
      this.plans_row_data = []
      if (ck1 && ck2) {
        var rowPlanData = {}
        for (let index = 0; index < this.plans_data.length; index++) {
          const row = this.plans_data[index]
          // // console.log(row)
          let pickup_time = this.getPickupTime(row.pickup_time_1, row.pickup_time_2)
          // var good_type_name = this.getGoodsTypeName(row.goods_type_id)s
          let vehicle_type_obj = row.vehicle_type_id.split('|')
          var truck_type_name = this.findTruckTypeName(vehicle_type_obj[0])
          var truck_type_group = this.dataTruckType[vehicle_type_obj[0]].id_group
          var status_n = this.getPlanStatus(row.order_status)
          rowPlanData = {
            // good_type_name: good_type_name,
            truck_type_group: truck_type_group,
            truck_type_name: truck_type_name,
            status_name: status_n,
            rawData: row,
            active_status: false,
          }
          // // console.log(rowPlanData)
          this.plans_row_data.push(rowPlanData)
        }
        this.plans_data_status = true
        // // console.log(this.plans_row_data)
      } else {
        this.plans_data_status = false
      }
      this.search_flag = false
    },
    getDateTime(datetime) {
      // แปลงเวลาเป็น format สำหรับแสดงบนเว็บ
      var ck1 = datetime !== null && datetime != null && datetime != undefined
      var data_return = this.lang.no_data_time
      if (ck1) {
        data_return = moment(datetime).format('dd DD/MM/YYYY HH:mm')
      }
      return data_return
    },
    getPickupTime(time1, time2) {
      var dateData = global_func.Between2Date(time1, time2)
      var diffTime = dateData.diffTime / 1000 / 60 / 60
      var date_return = ''
      if (diffTime > 23.9997222222 && diffTime < 24) {
        date_return = moment(time1).format('dd DD/MM/YYYY') + ' ' + '(ตลอดทั้งวัน)'
      } else {
        date_return = moment(time1).format('dd DD/MM/YYYY HH:mm') + ' - ' + moment(time2).format('dd DD/MM/YYYY HH:mm')
      }
      return date_return
    },
    search_plan(func) {
      var mode = func
      var data_serach = ''
      var search_date_start = ''
      var search_date_end = ''
      // // console.log(mode)
      if (mode == 'plan_code') {
        data_serach = this.search_plans_text
      } else if (mode == 'search_date_start') {
        search_date_start = this.search_date_start
        // if(this.data_serach != "" && this.data_serach != null){
        //     data_serach = this.search_plans_text
        // }
        // if(this.search_date_end != "" && this.search_date_end != null){
        //     search_date_end = this.search_date_end
        // }
      } else if (mode == 'search_date_end') {
        search_date_end = this.search_date_end
        // if(this.data_serach != "" && this.data_serach != null){
        //     data_serach = this.search_plans_text
        // }
        // if(this.search_date_start != "" && this.search_date_start != null){
        //     search_date_start = this.search_date_start
        // }
      }
      this.getPlansData(data_serach, mode)
    },
    getDataGoodsType() {
      // get ข้อมูล ประเภทสินค้า
      service.postService('getGoodsType').then(rp => {
        // // console.log(rp)
        let data = rp.data
        for (let index = 0; index < data.length; index++) {
          var goods_type_sel = {
            goods_type_id: data[index]['goods_type_id'],
            goods_type_name_th: data[index]['goods_type_name_th'],
            goods_type_name_en: data[index]['goods_type_name_en'],
            goods_type_status: '',
          }
          //this.dataGoodsType_master.push(goods_type_sel) // for insert data to db
          this.dataGoodsType_data.push(goods_type_sel)
          //if (this.account_lang != 'th') {
          //  this.goods_lang = false
          //}
        }
        // console.log(this.dataGoodsType_data)
      })
    },
    // --- แปลงข้อมูลจาก raw data ---
    getGoodsTypeName(goodsTypeId) {
      // นำ goods_type_id มาหาชื่อของประเภทสินค้า
      var data_return = ''
      if (goodsTypeId != null && goodsTypeId != '') {
        var res = goodsTypeId.split('|')
        var count_goods_type = 0
        // console.log(res)
        // console.log(this.dataGoodsType_data)
        for (let index2 = 0; index2 < res.length; index2++) {
          var goodsType_id = res[index2]
          if (count_goods_type < 2) {
            for (let index = 0; index < this.dataGoodsType_data.length; index++) {
              const element = this.dataGoodsType_data[index]
              if (element.goods_type_id == goodsType_id) {
                if (data_return != '') {
                  data_return += ', '
                  data_return += element.goods_type_name_th
                  if (!this.check_lang) {
                    data_return += element.goods_type_name_en
                  }
                } else {
                  data_return = element.goods_type_name_th
                  if (!this.check_lang) {
                    data_return += element.goods_type_name_en
                  }
                }
              }
            }
            count_goods_type++
          } else {
            data_return += ', ...'
          }
        }
      } else {
        data_return = '-'
      }
      // // console.log(data_return)
      return data_return
    },
    
    getPlanStatus(status) {
      // เช็คสถานะของ raw data ของ แผนงานนั้นๆ จาก status
      var data_return = ''
      if (status == '0') {
        data_return = 'รอเสนองาน'
      } else if (status == '1') {
        data_return = 'รอเสนองาน'
      } else if (status == '2') {
        data_return = 'รอดำเนินการ'
      } else if (status == '3') {
        data_return = 'อยู่ระหว่างขนส่ง'
      } else if (status == '4') {
        data_return = 'ดำเนินการเสร็จแล้ว'
      } else if (status == '5') {
        data_return = 'ปิดงาน'
      }
      return data_return
    },
    cilck_provider(row_index, type_provider) {
      
      // console.log('row_index',row_index)
      // console.log('type_provider',type_provider)
      let index = row_index
      if (type_provider == 'service') {
        if (this.plans_row_data[index].active_status) {
          this.plans_row_data[index].active_status = false
        } else {
          this.plans_row_data[index].active_status = true
        }
      } else {
        // if (this.offer_provider_data[index].active_status) {
        //   this.offer_provider_data[index].active_status = false
        // } else {
        //   this.offer_provider_data[index].active_status = true
        // }
      }
    },
    timeout_search_plans() {
      var that = this
      // // console.log('ok');
      this.search_flag = true
      clearTimeout(that.debounce)
      this.debounce = setTimeout(function() {
        that.search_plan('plan_code')
      }, 500)
    },
    open_modal_detail(data) {
      // console.log('data ja')
      // console.log(data.rawData)
      // console.log('pickup_time_2 '+ data.rawData.goods_type_id)
      //make detail
      var good_type_name = this.getGoodsTypeName(data.rawData.goods_type_id)
      var make_data = {
        id_order_tracking: data.rawData.id_order_tracking,
        created: data.rawData.created,
        order_period: data.rawData.order_period,
        // this.period_display = data.order_period_display
        good_type_name: good_type_name,
        quantity: data.rawData.quantity,
        question: data.rawData.question,
        arrival_address: data.rawData.arrival_address,
        arrival_lat_lon: data.rawData.arrival_lat_lon,
        departure_address: data.rawData.departure_address,
        departure_lat_lon: data.rawData.departure_lat_lon,
        total_distance: data.rawData.total_distance,
        vehicle_type_group_id: data.rawData.vehicle_type_group_id,
        truck_type_name: data.truck_type_name,
        other_service_display: data.rawData.other_service_display,
        pickup_time_1: data.rawData.pickup_time_1,
        pickup_time_2: data.rawData.pickup_time_2,
        expected_price: data.rawData.expected_price,
      }

      this.$refs.plandetail.bt_detail(make_data)
    },
    routersClick(value,type) {
      // eslint-disable-next-line no-undef
      // 0,1
      // 2,3,4
      let link = ''
      if(type == 0 || type == 1){
        link = 'plans_display'
      }else{
        link = 'tracking'
      }
      this.$router.push({ path: link + `/?data=${value.rawData.id_order_tracking}` })
    },
  },
}
</script>

<template>
  <Layout>
    <div style="padding: 2rem 2rem 0rem 2rem">
      <b-row>
        <b-col lg="2" class="col-margin-bot-1">
          <date-picker
            v-model="search_date_start"
            input-class="form-control"
            format="DD-MM-YYYY"
            type="date"
            placeholder="วันที่เริ่ม"
          />
        </b-col>
        <b-col lg="2" class="col-margin-bot-1">
          <date-picker
            v-model="search_date_end"
            input-class="form-control"
            format="DD-MM-YYYY"
            type="date"
            placeholder="วันที่สิ้นสุด"
          />
        </b-col>
        <b-col lg="2" class="col-margin-bot-1">
          <select v-model="sort_by_status" class="form-control" @change="getPlansData()">
            <option value="0" selected>สถานะทั้งหมด</option>
            <option value="1">รอเสนองาน</option>
            <option value="2">รอดำเนินการ</option>
            <option value="3">อยู่ระหว่างขนส่ง</option>
            <option value="4">ดำเนินการเสร็จแล้ว</option>
            <option value="5">ปิดงาน</option>
          </select>
        </b-col>
        <b-col lg="3" class="col-margin-bot-1">
          <div class="input-group prepend">
            <b-form-input
              id="search_plans_text"
              v-model="search_plans_text"
              type="text"
              class="form-control custom-input-prepend"
              :placeholder="lang.placeholder.search_plans_text"
              @keyup="timeout_search_plans()"
            />
            <div
              class="input-group-prepend custom-input-group-prepend"
              @click="search_plan('plan_code')"
            >
              <span class="input-group-text custom-input-group-prepend-text search">
                <i class="ri-search-line" />
              </span>
            </div>
          </div>
        </b-col>
        <!-- <b-col lg="2" class="col-margin-bot-1">
              <date-picker v-model="date_search" input-class="form-control" format="DD-MM-YYYY" type="date" :placeholder="วันที่เริ่ม" />
        </b-col>-->
      </b-row>
    </div>
    <hr />
    <simplebar id="bankS" ref="bankscrol" class="card-body" style="padding: 0px;height:calc(100vh - 240px);">
      <div v-if="search_flag" class="d-flex align-items-center justify-content-center">
        <span>
          <br />
          <br />
          <b-spinner class="m-2" variant="primary" role="status" />
        </span>
      </div>
      <div v-else>
        <b-row v-for="(option,index) in plans_row_data" :key="index" style="">
          <b-col lg="1">
            <b-row>
              <b-col style="text-align: center; font-size: 0.8rem;">
                <span>
                  {{ option.rawData.created | moment("dd DD/MM/YYYY HH:mm:ss") }}
                </span>
              </b-col>
            <!-- <b-col style="text-align: center; font-size: 0.8rem;">
              <span>
                13:45:33{{ option3.date_appointment | moment("HH:mm") }} 
              </span>
            </b-col> -->
            </b-row>
          </b-col>
          <b-col lg="6" class="align-items-start">
            <ul class="list-unstyled activity-wid" style="margin-top: 0px">
              <li class="activity-list">
                <div class="activity-icon avatar-xs">
                  <span class="avatar-title bg-soft-primary text-primary rounded-circle" style="align-items: flex-start">
                    <i :class="`ri-checkbox-blank-circle-fill`" />
                  </span>
                </div>
                <template>
                  <b-card lg="10" class="shadow-none service-provider-goods-row-auto-height" :class="{ active: plans_row_data[index].active_status }">
                    <div>
                      <b-row class="col-margin-bot-1">
                        <b-col lg="12" style="margin-left: 1rem; cursor:pointer" @click="cilck_provider(index, 'service')">
                          <b-row>
                            <b-col class="align-items-center">
                              <b-row>
                                <b-col>
                                  <span class="font-size-18" style="margin-right: 0.5rem">
                                    {{ option.rawData.id_order_tracking }}
                                  </span>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <span> สถานะ : </span>
                              <span v-if="option.rawData.order_status == '0'" style="color: #0098D1"> {{ option.status_name }} </span>
                              <span v-else-if="option.rawData.order_status == '1'" style="color: #0098D1"> {{ option.status_name }} </span>
                              <span v-else-if="option.rawData.order_status == '2'" style="color: #0098D1"> {{ option.status_name }} </span>
                              <span v-else-if="option.rawData.order_status == '3'" style="color: #0098D1"> {{ option.status_name }} </span>
                              <span v-else-if="option.rawData.order_status == '4'" style="color: #33C38F"> {{ option.status_name }} </span>
                              <span v-else-if="option.rawData.order_status == '5'" style="color: #0098D1"> {{ option.status_name }} </span>
                            </b-col>
                          </b-row>
                        </b-col>
                        <i class="fas fa-angle-down arrow" :class="{ active: plans_row_data[index].active_status }" style="position: absolute; top:20px; right:0; cursor: pointer;" @click="cilck_provider(index, 'service')" />
                      </b-row>
                    </div>
                
                    <div class="service-provider-goods-row-body-auto-heigth" :class="{ active: plans_row_data[index].active_status }">
                      <hr />
                      <b-row class="col-margin-bot-1" style="padding-left: 12px;">
                        <b-col lg="3" style="margin-bottom: 0.5rem">
                          <button class="btn btn-primary" style="color: #FFFFFF; border: 1px solid #B0BAC4;width:100%;" @click="routersClick(option,option.rawData.order_status)">
                            ไปหน้า "แผนงานขนส่ง"
                          </button>
                        </b-col>
                        <b-col lg="3" style="margin-bottom: 0.5rem">
                          <button class="btn " style="color: #343C48; background: #FFFFFF; border: 1px solid #B0BAC4;" @click="open_modal_detail(option)">
                            ดูแบบละเอียด
                          </button>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </template>
              </li>
            </ul>
          </b-col>
        </b-row>
      </div>
    </simplebar>
    <button v-show="activeScroll" style="display:scroll;position:fixed;bottom:10px;right:35px;z-index:999999999999;border:0;background:#ffffff00;" @click="toTop"><img style="width:50px" src="@/assets/images/imgbank/bankscroll.png" /></button>
    <PlanDetail ref="plandetail" :obj="{}" />
  </Layout>
</template>

<style>
body {
  overflow: hidden;
}
hr {
  border: 1px solid #e4e4e4;
}
.col-main-menu-tran {
  padding: 0px;
}
.h-100vh {
  height: 100% !important;
}
.plans-row-data {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b0bac4;
  border-radius: 5px;
  opacity: 1;
  max-height: 230px;
  min-height: 230px;
  padding: 1rem;
}
.plans-row-data:hover {
  transition: 0.3s;
  border: 1px solid #03aeef;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.plans-col {
  /* transition: all 1s; */
  display: inline-block;
  margin: 0 0 0.5rem 0;
}
.plans-main-display {
  max-height: 77.5vh;
  min-height: 77.5vh;
}
.plans-row-data-title {
  font-size: 24px;
  font-weight: 500;
}
.plans-row-data-content {
  font-size: 16px;
  font-weight: 500;
}
.custom-margin {
  margin-bottom: 0.5rem;
}
.summary-of-plan.active {
  position: absolute;
  width: 100%;
  z-index: 13;
}
.plans_detail_title {
  font-size: 32px;
  font-weight: 500;
}
.avatar-title {
  /* background-color: #fff !important; */
  background-color: hsla(197, 100%, 89%, 0) !important;
}
.col-margin-bot-1 {
  margin-bottom: 0.6rem;
}
.service-provider-display {
  padding: 20px;
}
</style>
