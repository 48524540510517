<script>
import i18n from '@/i18n'
import service from '@/server/http_service'
import VueEasyLightbox from 'vue-easy-lightbox'
import ModalRouteService from '@/components/modal/route_service_display'
import ModalReviewEdit from '@/components/modal/review_edit'
import simplebar from 'simplebar-vue'
import moment from 'moment'
import global_func from '@/global_function'

/**
 * Starter page component
 */
export default {
  page: {
    // title: 'หน้าว่าง บ',
    meta: [{ name: 'description' }],
  },
  components: { VueEasyLightbox, ModalRouteService, simplebar, ModalReviewEdit },
  props: {
    obj: {
      type: Object,
      default: () => {
        return { message: 'no obj' }
      },
    },
  },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('company_detail')
    return {
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      lang: lang_obj,
      provider_id: '',
      active_tab: 0,
      // company info part
      company_raw_data: [],
      company_data: {},
      // vehicle
      vehicle_raw_data: [],
      vehicle_data: [],
      // route service
      route_raw_data: [],
      route_show_data: [],
      data_prov_data: [],
      // file
      file_raw_data: [],
      pic_data: [],
      imgs: '', // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
      lightboximgs: [],
      rating: 4,
      rating_text: "4.0",
      count_score_1: 0,
      count_score_2: 0,
      count_score_3: 0,
      count_score_4: 0,
      count_score_5: 0,
      review_raw_data: [],
      review_data_master: [],
      review_data_show: [],
      review_group_option_all: 'big_btn_blue',
      review_group_option_1: 'big_btn_white',
      review_group_option_2: 'big_btn_white',
      review_group_option_3: 'big_btn_white',
      review_group_option_4: 'big_btn_white',
      review_group_option_5: 'big_btn_white',
      modal_review_flg: false,
      modal_review_comp: "",
    }
  },
  watch: {},
  beforeMount() { },
  mounted() { },
  methods: {
    bt_detail() {
      var that = this
      that.$bvModal.show('modal_company_detail')
      setTimeout(() => {
        that.getCompanyDataById()
      }, 300)
    },
    bt_detail_route() {
      var that = this
      this.$refs.prov_service.checkDataProv(this.route_raw_data)
      that.$bvModal.show('modal_route_service')
    },
    setReviewData() {
      this.changeReviewOption('all')
      this.count_score_all = 0
      this.count_score_1 = 0
      this.count_score_2 = 0
      this.count_score_3 = 0
      this.count_score_4 = 0
      this.count_score_5 = 0
      this.rating_text = "0"
      this.review_data_master = []
      this.review_data_show = []

      service
        .postService('getReviewCompanyData', {
          provider_id: this.provider_id,
        })
        .then(rp => {
          // console.log(rp)

          if (rp.review_data.length > 0) {
            this.review_raw_data = rp.review_data
            let total_review_score = 0
            for (let i = 0; i < rp.review_data.length; i++) {
              const row = rp.review_data[i]
              let profile_pic = ""
              let create_at = moment(row.created).format("YYYY-MM-DD HH:mm:ss")
              let status_flg = row.status_flg

              if(row.profile_pic.length > 0 ) {
                profile_pic = row.profile_pic[0].blob
              }

              if(moment().subtract(7, "days") > create_at) {
                status_flg = 1
              }

              let obj = {
                profile: profile_pic,
                name: row.mover_name,
                rating: row.rating_review,
                comment: row.comment,
                id_member: row.id_member,
                create_at: create_at,
                status_flg: status_flg,
                id_assesment: row.id_assesment,
              }
              this.review_data_show.push(obj)

              if (row.rating_review > 4) {
                this.count_score_5++
              } else if (row.rating_review > 3) {
                this.count_score_4++
              } else if (row.rating_review > 2) {
                this.count_score_3++
              } else if (row.rating_review > 1) {
                this.count_score_2++
              } else {
                this.count_score_1++
              }
              total_review_score = Number(total_review_score) + Number(row.rating_review)
            }
            this.review_data_master = this.review_data_show
            this.count_score_all = rp.review_data.length
            this.rating_text = parseFloat(total_review_score) / parseFloat(this.count_score_all)
            this.rating_text = this.rating_text.toFixed(1)
            this.rating = this.rating_text
          }
        })
    },
    setProviderData(data, modal_review) {
      var that = this
      this.provider_id = data.provider_id
      let ck = !!modal_review
      if(ck) {
        this.modal_review_comp = modal_review
      } else {
        this.modal_review_flg = true
      }
      // console.log(this.provider_id)
      this.setDefault()
      that.$bvModal.show('modal_company_detail')
      setTimeout(() => {
        that.getCompanyDataById()
      }, 300)
    },
    setReviewDataEdit(id_assesment) {
      // console.log(id_assesment)
      let data = this.review_raw_data.find(e =>{
        return e.id_assesment == id_assesment
      })
      let body = {
        id_assesment: data.id_assesment,
        id_member: this.account_data[0].id_member,
        id_order: data.id_order,
        id_carrier: data.id_carrier,
        provider_id: data.id_provider,
      }
      this.$refs.modalReviewEdit.setEditReviewData(body)

    },
    getCompanyDataById() {
      var that = this
      service
        .postService('getCompanyDataById', {
          provider_id: that.provider_id,
        })
        .then(rp => {
          // console.log(rp)
          this.company_raw_data = []
          this.vehicle_raw_data = []
          this.route_raw_data = []
          this.file_raw_data = []
          this.company_raw_data = rp.company_data[0]
          this.vehicle_raw_data = rp.car_data
          this.route_raw_data = rp.route_data
          this.file_raw_data = rp.file_data
          setTimeout(() => {
            that.convert_raw_data()
          }, 300)
        })
    },
    convert_raw_data() {
      var data = this.company_raw_data
      var element = data
      // console.log(element)
      this.company_data = {
        company_id: element.id_member,
        company_name: element.mover_name,
        company_type: element[this.changeLangData('business_name')],
        company_tel: element.mover_tel,
        company_email: element.mover_mailaddr,
        company_address: element.mover_address1,
        star_score: element.user_score.star_score,
        lover_score: element.user_score.lover_score,
      }

      var data2 = this.file_raw_data
      var pic_obj = []
      for (let index = 0; index < data2.length; index++) {
        const element2 = data2[index]
        if (element2.title == 'pic_mover') {
          // var path = require('/../file_upload_dtc_backhaul/images/pic_mover/' + element2.filename)
          var path = element2.blob
          pic_obj.push(path)
        }
        if (element2.title == 'profile') {
          // var path = require('/../file_upload_dtc_backhaul/images/pic_mover/' + element2.filename)
          var path2 = element2.blob
          this.company_data.pic_profile = path2
        }
      }
      this.lightboximgs = pic_obj
    },
    changeLangData(func) {
      var data_return = ''
      if (this.account_lang == 'th') {
        if (func == 'business_name') {
          data_return = 'business_type_name_th'
        } else if (func == 'vehicle_name') {
          data_return = 'name_group_th'
        } else if (func == 'geo_name') {
          data_return = 'name'
        }
      } else {
        if (func == 'business_name') {
          data_return = 'business_type_name_en'
        } else if (func == 'vehicle_name') {
          data_return = 'name_group_en'
        } else if (func == 'geo_name') {
          data_return = 'name'
        }
      }
      return data_return
    },
    showImg(index) {
      this.index = index
      this.visible = true
    },
    select_prov: function (data_prov_data) {
      // console.log(data_prov_data)
      this.data_prov_data = data_prov_data
    },
    changeReviewOption(option) {

      this.changeReviewData(option)

      if (option == 'all') {
        this.review_group_option_all = 'big_btn_blue'
      } else {
        this.review_group_option_all = 'big_btn_white'
      }

      if (option == '1') {
        this.review_group_option_1 = 'big_btn_blue'
      } else {
        this.review_group_option_1 = 'big_btn_white'
      }

      if (option == '2') {
        this.review_group_option_2 = 'big_btn_blue'
      } else {
        this.review_group_option_2 = 'big_btn_white'
      }

      if (option == '3') {
        this.review_group_option_3 = 'big_btn_blue'
      } else {
        this.review_group_option_3 = 'big_btn_white'
      }

      if (option == '4') {
        this.review_group_option_4 = 'big_btn_blue'
      } else {
        this.review_group_option_4 = 'big_btn_white'
      }

      if (option == '5') {
        this.review_group_option_5 = 'big_btn_blue'
      } else {
        this.review_group_option_5 = 'big_btn_white'
      }
    },
    changeReviewData(option) {
      this.review_data_show = []
      if (option != 'all') {
        this.review_data_show = this.review_data_master.filter(e => {
          if (e.rating == option) {
            return e
          }
        })
      } else {
        this.review_data_show = this.review_data_master
      }
    },
    setDefault() {
      this.active_tab = 0
    },
    sendEditReview(agEditData) {
      // console.log(agEditData)
      global_func.CloseMsg()
      if(agEditData == "ok") {
        global_func.alertMessage('แก้ไขข้อมูลสำเร็จแล้ว', '', 'success')
        this.setReviewData()
      } else {
        global_func.alertMessage('แก้ไขข้อมูลล้มเหลว', '', 'error')
      }
    },
  },
}
</script>

<template>
  <div>
    <!-- <span class="font-size-16">ข้อมูลบริษัท : </span> -->
    <!-- <b-link class="font-size-16" href="#" @click="bt_detail">{{ lang.detail }}</b-link> -->
    <!-- <span @click="bt_detail">{{ lang.detail }}</span> -->
    <b-modal id="modal_company_detail" centered size="xl" :title="lang.title" title-class="font-size-24" hide-footer>
      <div class="modal-detail-body-custom">
        <nav>
          <b-tabs v-model="active_tab" justified nav-class="nav-tabs-custom">
            <b-tab :active="active_tab == 0">
              <template v-slot:title>
                <span class="d-none d-sm-inline-block topnav-text">ข้อมูล</span>
              </template>
            </b-tab>
            <b-tab :active="active_tab == 1" @click="setReviewData()">
              <template v-slot:title>
                <span class="d-none d-sm-inline-block topnav-text">รีวิว</span>
              </template>
            </b-tab>
            <b-tab :active="active_tab == 2" disabled />
            <b-tab :active="active_tab == 3" disabled />
            <b-tab :active="active_tab == 4" disabled />
            <b-tab :active="active_tab == 5" disabled />
          </b-tabs>
        </nav>
        <template v-if="active_tab == 0">
          <br />
          <b-row class="col-margin-bot-2">
            <b-col lg="1">
              <div class="d-flex align-items-center">
                <img v-if="company_data.pic_profile" :src="company_data.pic_profile" width="80px" height="80px" class="mr-3 rounded-circle avatar-md" />
                <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" width="80px" height="80px" class="mr-3 rounded-circle avatar-md" />
              </div>
            </b-col>
            <b-col lg="11">
              <span class="font-size-24">
                {{ company_data.company_name }}
              </span>
              <br />
              <span class="font-size-18">
                {{ company_data.company_address }}
              </span>
            </b-col>
          </b-row>
          <b-row class="font-size-16">
            <b-col>
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  {{ lang.tab_data.interested_person }}
                </b-col>
                <b-col class="d-flex align-items-center">
                  <img src="@/assets/images/plans_display/star@2x.png" width="16px" height="16px" />
                  <span class="font-size-14" style="margin-right: 0.5rem; margin-left: 0.5rem"> ( {{
                    company_data.star_score
                  }} ) </span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  {{ lang.tab_data.tel }}
                </b-col>
                <b-col class="font-color-blue">
                  <span>
                    {{ company_data.company_tel }}
                  </span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  {{ lang.tab_data.bus_type }}
                </b-col>
                <b-col class="font-color-blue">
                  <span v-if="company_data.company_type != null && company_data.company_type != ''">
                    {{ company_data.company_type }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  {{ lang.tab_data.car_service }}
                </b-col>
                <b-col class="font-color-blue">
                  <div v-for="(option, index) in vehicle_raw_data" :key="index">
                    <template v-if="vehicle_raw_data.length > 0">
                      <span>
                        {{ option[changeLangData('vehicle_name')] }}
                      </span>
                      <br />
                    </template>
                    <template v-else>
                      <span>
                        -
                      </span>
                    </template>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  {{ lang.tab_data.Rating }}
                </b-col>
                <b-col class="d-flex align-items-center">
                  <img src="@/assets/images/plans_display/lover.png" width="16px" height="16px" />
                  <span class="font-size-14" style="margin-right: 0.5rem; margin-left: 0.5rem"> ( {{
                    company_data.lover_score
                  }} ) </span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  {{ lang.tab_data.email }}
                </b-col>
                <b-col class="font-color-blue">
                  <span v-if="company_data.company_email != null && company_data.company_email != ''">
                    {{ company_data.company_email }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </b-col>
              </b-row>
              <b-row class="col-margin-bot-2" />
              <b-row class="col-margin-bot-1">
                <b-col lg="3">
                  {{ lang.tab_data.route }}
                </b-col>
                <b-col class="font-color-blue">
                  <span v-for="(option2, index2) in route_raw_data" :key="index2">
                    {{ option2[changeLangData('geo_name')] }}
                  </span>
                  <!-- {{ route_raw_data }} -->
                  <!-- <b-link class="font-size-16" href="#" @click="bt_detail_route">ดูแบบละเอียด</b-link>
                  <b-modal id="modal_route_service" centered size="xl" :title="lang.title" title-class="font-size-24" hide-footer>
                    <ModalRouteService :obj="data_prov_data" @select_prov="select_prov" />
                  </b-modal> -->
                  <br v-if="route_raw_data.length > 0" />
                  <b-link class="font-size-16" href="#" @click="bt_detail_route">
                    {{ lang.tab_data.route_detail }}
                  </b-link>
                  <ModalRouteService ref="prov_service" :obj="[]" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr />
          <div class="popup-gallery d-flex justify-content-center">
            <a v-for="(item, index3) in lightboximgs" :key="index3" class="float-left" @click="() => showImg(index3)">
              <b-col lg="4">
                <div class="img-fluid">
                  <img :src="item" alt width="293" />
                </div>
              </b-col>
            </a>
          </div>
          <vue-easy-lightbox :visible="visible" :index="index" :imgs="lightboximgs" @hide="visible = false" />
        </template>
        <template v-else-if="active_tab == 1">
          <br />
          <b-row>
            <b-col cols="4" />
            <b-col cols="4" class="d-flex align-items-center">
              <b-form-rating v-model="rating" no-border readonly size="md">
                <template v-slot:icon-empty>
                  <b-icon icon="heart" scale="3" color="#B0BAC4" />
                </template>
                <template v-slot:icon-half>
                  <b-icon icon="heart-half" scale="3" color="#FF718B" />
                </template>
                <template v-slot:icon-full>
                  <b-icon icon="heart-fill" scale="3" color="#FF718B" />
                </template>
              </b-form-rating>
              <span style="font-size: 24px; font-weight: 500;">
                {{ rating_text }}
              </span>
            </b-col>
            <b-col cols="4" />
          </b-row>
          <br />
          <b-row>
            <b-col>
              <div :class="review_group_option_all" class="d-flex justify-content-center"
                   @click="changeReviewOption('all')"
              >
                <span class="margin-top-bot-auto">{{ lang.tab_review.btn.all }} ({{ count_score_all }})</span>
              </div>
            </b-col>
            <b-col>
              <div :class="review_group_option_5" class="d-flex justify-content-center"
                   @click="changeReviewOption('5')"
              >
                <span class="margin-top-bot-auto">{{ lang.tab_review.btn.score5 }} ({{ count_score_5 }})</span>
              </div>
            </b-col>
            <b-col>
              <div :class="review_group_option_4" class="d-flex justify-content-center"
                   @click="changeReviewOption('4')"
              >
                <span class="margin-top-bot-auto">{{ lang.tab_review.btn.score4 }} ({{ count_score_4 }})</span>
              </div>
            </b-col>
            <b-col>
              <div :class="review_group_option_3" class="d-flex justify-content-center"
                   @click="changeReviewOption('3')"
              >
                <span class="margin-top-bot-auto">{{ lang.tab_review.btn.score3 }} ({{ count_score_3 }})</span>
              </div>
            </b-col>
            <b-col>
              <div :class="review_group_option_2" class="d-flex justify-content-center"
                   @click="changeReviewOption('2')"
              >
                <span class="margin-top-bot-auto">{{ lang.tab_review.btn.score2 }} ({{ count_score_2 }})</span>
              </div>
            </b-col>
            <b-col>
              <div :class="review_group_option_1" class="d-flex justify-content-center"
                   @click="changeReviewOption('1')"
              >
                <span class="margin-top-bot-auto">{{ lang.tab_review.btn.score1 }} ({{ count_score_1 }})</span>
              </div>
            </b-col>
          </b-row>
          <br />
          <simplebar style="height:calc(100vh - 450px); padding: 0px 20px 0px 0px;font-size:15px">
            <div v-for="(option, index) in review_data_show" :key="index + 1" lg="12">
              <b-row style="margin: 0px 0px 1.5% 0px" class="row-comment">
                <b-col lg="1">
                  <div class="d-flex align-items-center">
                    <img v-if="option.profile != ''" :src="option.profile" width="60px" height="60px" class="rounded-circle avatar-md" />
                    <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" width="60px" height="60px" class="rounded-circle avatar-md" />
                  </div>
                </b-col>
                <b-col lg="10">
                  <div class="d-flex align-items-center">
                    <div style="width: 22%">
                      <b-form-rating v-model="option.rating" no-border readonly size="xs">
                        <template v-slot:icon-empty>
                          <b-icon icon="heart" scale="2" color="#B0BAC4" />
                        </template>
                        <template v-slot:icon-half>
                          <b-icon icon="heart-half" scale="2" color="#FF718B" />
                        </template>
                        <template v-slot:icon-full>
                          <b-icon icon="heart-fill" scale="2" color="#FF718B" />
                        </template>
                      </b-form-rating>
                    </div>
                    <div style="font-size: 20px; font-weight: 500;">
                      {{ option.comment }}
                    </div>
                  </div>
                  <div class="form-control border-0">
                    {{ option.name }}
                  </div>
                </b-col>
                <b-col lg="1">
                  <template v-if="account_data[0].id_member == option.id_member && option.status_flg == '0'">
                    <b-dropdown class="dropdown-hover" size="xs" variant="link" toggle-class="text-decoration-none" no-caret dropleft>
                      <template v-slot:button-content>
                        <img src="@/assets/images/plans_display/menu@2x.png" width="28px" height="9" />
                      </template>
                      <b-dropdown-item @click="setReviewDataEdit(option.id_assesment)">{{ lang.tab_review.dropdown.edit }}</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-col>
              </b-row>
              <hr />
            </div>
          </simplebar>
        </template>
      </div>
    </b-modal>
    <ModalReviewEdit ref="modalReviewEdit" :obj="{}" @fin-edit="sendEditReview" />
  </div>
</template>
<style scoped>
.modal-detail-body-custom {
  padding: 1rem 1rem;
}

.modal-scrollbar {
  overflow: hidden scroll;
}

.modal-scrollbar {
  overflow: auto;
}

.dropdown-hover {
  opacity: 0;
  transition: 0.1s;
}

.row-comment:hover .dropdown-hover {
  opacity: 1;
}

hr {
  border: 1px solid #e4e4e4;
}
</style>
