<script>
import HorizontalTopbar from '@/components/horizontal-topbar'
import HorizontalNav from '@/components/horizontal-navbar'
import RightBar from '@/components/right-sidebar'
import Footer from '@/components/footer'

import { layoutComputed } from '@/state/helpers'

/**
 * Horizontal-layout
 */
export default {
  components: {
    HorizontalTopbar,
    HorizontalNav,
    Footer,
    RightBar,
  },
  data() {
    return {}
  },
  computed: {
    ...layoutComputed,
  },
  created: () => {
    document.body.setAttribute('data-layout', 'horizontal')
    document.body.setAttribute('data-topbar', 'dark')
    document.body.removeAttribute('data-sidebar', 'dark')
    document.body.removeAttribute('data-layout-size', 'boxed')
  },
  mounted() {
    document.body.classList.remove('auth-body-bg')
    if (this.loader === true) {
      document.getElementById('preloader').style.display = 'block'
      document.getElementById('status').style.display = 'block'

      setTimeout(function() {
        document.getElementById('preloader').style.display = 'none'
        document.getElementById('status').style.display = 'none'
      }, 2500)
    } else {
      document.getElementById('preloader').style.display = 'none'
      document.getElementById('status').style.display = 'none'
    }
  },
  methods: {
    toggleRightSidebar() {
      document.body.classList.toggle('right-bar-enabled')
    },
    hideRightSidebar() {
      document.body.classList.remove('right-bar-enabled')
    },
  },
}
</script>
<template>
  <div>
    <div id="preloader">
      <div id="status">
        <div class="spinner">
          <i class="ri-loader-line spin-icon" />
        </div>
      </div>
    </div>
    <!-- Begin page -->
    <div id="layout-wrapper">
      <HorizontalTopbar :type="topbar" :width="layoutWidth" />
      <HorizontalNav />
      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <slot />
          </div>
          <!-- container-fluid -->
        </div>
        <!-- End Page-content -->
        <Footer />
      </div>
      <!-- end main content-->
    </div>
    <!-- END layout-wrapper -->
    <RightBar />
  </div>
</template>
<style>
  .page-content {
    margin:auto;
    margin-top: 55px !important;
    padding: calc(45px + 14px) 0px 0px 0px !important;
  }
</style>
