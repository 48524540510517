<script>
import { layoutMethods } from '@/state/helpers'
// import { menuItems } from './horizontal-menu'

export default {
  data() {
    return {
      active_tab: 1,
    }
  },
  beforeMount() {
    // var tab = document.getElementsByClassName('topnav')
    // console.log(this.$route.path)
    var url = this.$route.path
    // console.log(url.search('/transportation_plan/create'))
    if(url.search('/transportation_plan/create') != -1) {
      this.active_tab = 0
    }
    if(url.search('/transportation_plan/plans_display') != -1) {
      this.active_tab = 1
    }
    if(url.search('/blank_chot') != -1) {
      this.active_tab = 5
    }
    if(url.search('/transportation_plan/search') != -1) {
      this.active_tab = 2
    }
    if(url.search('/transportation_plan/tracking') != -1) {
      this.active_tab = 3
    }
    if(url.search('/transportation_plan/history') != -1) {
      this.active_tab = 4
    }
  },
  mounted() {
    var links = document.getElementsByClassName('side-nav-link-ref')
    var matchingMenuItem = null
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i]
        break
      }
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active')
      var parent = matchingMenuItem.parentElement

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active')
        const parent2 = parent.parentElement
        if (parent2) {
          parent2.classList.add('active')
        }
        const parent3 = parent2.parentElement
        if (parent3) {
          parent3.classList.add('active')
          var childAnchor = parent3.querySelector('.has-dropdown')
          if (childAnchor) childAnchor.classList.add('active')
        }

        const parent4 = parent3.parentElement
        if (parent4) parent4.classList.add('active')
        const parent5 = parent4.parentElement
        if (parent5) parent5.classList.add('active')
      }
    }
  },
  methods: {
    ...layoutMethods,

    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault()
      const nextEl = event.target.nextSibling
      if (nextEl && !nextEl.classList.contains('show')) {
        const parentEl = event.target.parentNode
        if (parentEl) {
          parentEl.classList.remove('show')
        }
        nextEl.classList.add('show')
      } else if (nextEl) {
        nextEl.classList.remove('show')
      }
      return false
    },
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false
    },
    change_layout(layout) {
      return this.changeLayoutType({ layoutType: layout })
    },
    topbarLight() {
      document.body.setAttribute('data-topbar', 'light')
      document.body.removeAttribute('data-layout-size', 'boxed')
    },
    boxedWidth() {
      document.body.setAttribute('data-layout-size', 'boxed')
      document.body.removeAttribute('data-topbar', 'light')
      document.body.setAttribute('data-topbar', 'dark')
    },
    routersClick(link) {
      // eslint-disable-next-line no-undef
      this.$router.push({ path: link })
      // console.log(this.$route.query.page)
    },
  },
}
</script>

<template>
  <div class="topnav">
    <div>
      <nav>
        <b-tabs v-model="active_tab" justified nav-class="nav-tabs-custom">
          <b-tab :active="active_tab == 0" @click="routersClick('/transportation_plan/create')">
            <template v-slot:title>
              <div class="navbar-text-custom">
                <img v-if="active_tab == 0" src="@/assets/images/right_bar_menu/active/plus (1).svg" height="18px" width="18px" class="icon-side-nav-menu" />
                <img v-else src="@/assets/images/right_bar_menu/plus (1).svg" height="18px" width="18px" class="icon-side-nav-menu" />
                <span class="d-none d-sm-inline-block topnav-text">{{ $t('top_navbar_menu.add_plan_goods') }}</span>
              </div>
            </template>
          </b-tab>
          <b-tab :active="active_tab == 1" @click="routersClick('/transportation_plan/plans_display')">
            <template v-slot:title>
              <div class="navbar-text-custom">
                <img v-if="active_tab == 1" src="@/assets/images/right_bar_menu/active/box.svg" height="18px" width="18px" class="icon-side-nav-menu" />
                <img v-else src="@/assets/images/right_bar_menu/box.svg" height="18px" width="18px" class="icon-side-nav-menu" />
                <span class="d-none d-sm-inline-block topnav-text">{{ $t('top_navbar_menu.add_plan_truck') }}</span>
              </div>
            </template>
          </b-tab>
          <b-tab :active="active_tab == 2" @click="routersClick('/transportation_plan/search')">
            <template v-slot:title>
              <div class="navbar-text-custom">
                <img v-if="active_tab == 2" src="@/assets/images/right_bar_menu/active/Path 5866.svg" height="18px" width="18px" class="icon-side-nav-menu" />
                <img v-else src="@/assets/images/right_bar_menu/Path 5866.svg" height="18px" width="18px" class="icon-side-nav-menu" />
                <span class="d-none d-sm-inline-block topnav-text">{{ $t('top_navbar_menu.find_car') }}</span>
              </div>
            </template>
          </b-tab>
          <b-tab :active="active_tab == 3" @click="routersClick('/transportation_plan/tracking')">
            <template v-slot:title>
              <div class="navbar-text-custom">
                <img v-if="active_tab == 3" src="@/assets/images/right_bar_menu/active/Group 4988.svg" height="18px" width="18px" class="icon-side-nav-menu" />
                <img v-else src="@/assets/images/right_bar_menu/Group 4988.svg" height="18px" width="18px" class="icon-side-nav-menu" />
                <span class="d-none d-sm-inline-block topnav-text">{{ $t('top_navbar_menu.tracking_goods') }}</span>
              </div>
            </template>
          </b-tab>
          <b-tab :active="active_tab == 4" @click="routersClick('/transportation_plan/history')">
            <template v-slot:title>
              <div class="navbar-text-custom">
                <img v-if="active_tab == 4" src="@/assets/images/right_bar_menu/active/Group 9846.svg" height="18px" width="18px" class="icon-side-nav-menu" />
                <img v-else src="@/assets/images/right_bar_menu/Group 9846.svg" height="18px" width="18px" class="icon-side-nav-menu" />
                <span class="d-none d-sm-inline-block topnav-text">{{ $t('top_navbar_menu.history_tran') }}</span>
              </div>
            </template>
          </b-tab>
          <b-tab :active="active_tab == 5" disabled>
            <template v-slot:title>
              <!-- <img v-if="active_tab == 5" src="@/assets/images/right_bar_menu/active/Group 4988.svg" height="18px" width="18px" class="icon-side-nav-menu" />
              <img v-else src="@/assets/images/right_bar_menu/Group 4988.svg" height="18px" width="18px" class="icon-side-nav-menu" />
              <span class="d-none d-sm-inline-block topnav-text">{{ $t('top_navbar_menu.send_offer') }}</span> -->
            </template>
          </b-tab>
          <b-tab :active="active_tab == 6" disabled>
            <template v-slot:title>
              <!-- <img src="@/assets/images/right_bar_menu/Group 4988.svg" height="18px" width="18px" class="icon-side-nav-menu" /> -->
              <!-- <span class="d-none d-sm-inline-block">=</span> -->
            </template>
          </b-tab>
          <b-tab :active="active_tab == 7" disabled>
            <template v-slot:title>
              <!-- <img src="@/assets/images/right_bar_menu/Group 4988.svg" height="18px" width="18px" class="icon-side-nav-menu" /> -->
              <!-- <span class="d-none d-sm-inline-block">=</span> -->
            </template>
          </b-tab>
        </b-tabs>
      </nav>
    </div>
  </div>
</template>

<style>
  .topnav {
    padding: 0;
  }
  .nav-link.active img.icon-side-nav-menu {
    fill: #0098D1;
  }
  .icon-side-nav-menu {
    margin-right: 0.8rem;
  }
  .topnav-text {
    font-weight: 300;
    font-size: 16px;
  }
  .navbar-text-custom {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }
</style>
